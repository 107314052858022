<template>
  <b-overlay
    :show="loadingSpinner"
    rounded="sm"
    opacity="0.6"
    spinner-small
    spinner-variant="secondary"
  >
    <Breadcrumb class="mt-4 mb-4"></Breadcrumb>
    <section id="listaManad">
      <div class="container jumbotron">
        <b-tabs class="titleColor">
          <b-tab active class="pt-2" @click="atualizaPagina()">
            <template #title>
              Cálculo
              <span class="badge badge-pill badge-primary">
                <span class="sr-only">Cálculo</span>
              </span>
            </template>
            <b-overlay>
              <div class="p-5">
                <div class="d-flex justify-content-center w-100">
                  <label><strong>Contribuinte :</strong></label>
                  {{ this.NomeContribuinte.toUpperCase() }}
                  <br />
                </div>
                <div class="d-flex justify-content-center w-100">
                  <button
                    class="btn btn-warning"
                    @click="verificarDuplicidade()"
                  >
                    Verificar duplicidade
                  </button>
                </div>

                <Calculo_Inss
                  :ActivateGetCalculo="reloadListCalc"
                  @resetReloadList="reloadListCalc = $event"
                >
                </Calculo_Inss>
              </div>
            </b-overlay>
          </b-tab>

          <b-tab class="pt-2" @click="getManadRubricas()">
            <template #title>
              Atualizar E-Social
              <span class="badge badge-pill badge-primary">
                <span class="sr-only">Manads Rubricas</span>
              </span>
            </template>
            <b-overlay>
              <div class="p-5">
                <div class="d-flex justify-content-center w-100">
                  <label><strong>Contribuinte : </strong></label>
                  {{ this.NomeContribuinte.toUpperCase() }}
                </div>
                <br />
                <label>Data E-social</label>
                <b-input placeholder="MÊS/ANO" v-model="anoCorte"></b-input>
                <br />
                <br />
                <p>
                  <b-button size="sm" class="btnSelect" @click="selectAllRows"
                    >Selecionar todos</b-button
                  >
                  <b-button size="sm" class="btnSelect" @click="clearSelected"
                    >Limpar</b-button
                  >
                </p>
                <b-table
                  hover
                  sticky-header
                  :items="ListaRubricas"
                  :fields="fields"
                  :filter="filter"
                  :small="small"
                  responsive="xl"
                  ref="selectableTable"
                  selectable
                  @row-selected="onRowSelected"
                >
                  <!-- <template #cell(selected)="{ rowSelected }">                   -->
                  <template> </template>
                </b-table>
                <p>
                  <b-button
                    variant="info"
                    class="btnSelect"
                    @click="atualizarEsocial"
                    >Atualizar E-social</b-button
                  >
                </p>
              </div>
            </b-overlay>
          </b-tab>

          <b-tab class="pt-2" @click="atualizaPagina()">
            <template #title>
              Atualiza RAT
              <span class="badge badge-pill badge-primary">
                <span class="sr-only">Atualizar RAT</span>
              </span>
            </template>
            <b-overlay>
              <div class="p-5">
                <div class="d-flex justify-content-center w-100">
                  <label><strong>Contribuinte :</strong></label>
                  {{ this.NomeContribuinte.toUpperCase() }}
                  <br />
                  <br />
                </div>
                <Aliquotas_Inss
                  :ActivateGetRAT="reloadRAT"
                  @resetReloadRAT="reloadRAT = $event"
                ></Aliquotas_Inss>
              </div>
            </b-overlay>
          </b-tab>
        </b-tabs>
      </div>
    </section>
  </b-overlay>
</template>

<script>
import inssService from "@/services/teses/inss.service";
import Calculo_Inss from "@/Components/Teses/Inss/Calculo_Inss.vue";
import UploadInssService from "@/services/uploadInss.service.js";
import HelperService from "@/services/helper.service";
import Aliquotas_Inss from "@/Components/Teses/Inss/Aliquotas_Inss.vue";
import Breadcrumb from "@/Components/Breadcrumb/Breadcrumb.vue";

export default {
  name: "manadRubricaPesquisa",
  components: {
    Calculo_Inss,
    inssService,
    Aliquotas_Inss,
    Breadcrumb,
  },
  data() {
    return {
      nCodOp: null,
      loadingSpinner: false,
      CodigoRubrica: null,
      DescricaoRubrica: null,
      Reg: null,
      CNPJ: null,
      NomeContribuinte: null,
      ListaRubricas: [],
      fields: [
        // { key: "nCodOp" },
        {
          key: "codigo_Rubrica",
          label: "Código rúbrica",
          sortable: true,
          class: "text-center",
        },
        { key: "descricao", label: "Descrição da verba", sortable: true },
        {
          key: "valor_INSS",
          label: "Valor INSS",
          sortable: true,
          formatter: (value, key, item) => {
            return new String(
              value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            );
          },
        },
        {
          key: "valor_RAT",
          label: "Valor RAT",
          sortable: true,
          formatter: (value, key, item) => {
            return new String(
              value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            );
          },
        },
        {
          key: "valor_S5",
          label: "Valor S5",
          sortable: true,
          formatter: (value, key, item) => {
            return new String(
              value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            );
          },
        },
        {
          key: "valor_Total",
          label: "Valor total",
          sortable: true,
          formatter: (value, key, item) => {
            return new String(
              value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            );
          },
        },
      ],
      selected: [],
      filter: "",
      small: true,
      // fields2: [
      //   { key: "nCodOp" },
      //   { key: "cod_rubrica", label: "Código Rubrica" },
      //   { key: "desc_rubrica", label: "Descrição Rubrica" },
      // ],
      RubricasBaseCalculo: [],
      aliquotas: {
        AliquotaInss: "20",
        AliquotaS5: "5,8",
        AliquotaRAT: "1,5",
      },
      anoCorte: "",
      ListaAtualizaEsocial: [],
      boxTwo: "",
      reloadListCalc: false,
      reloadRAT: false,
      nomeCliente: "",
      cnpj: "",
    };
  },
  mounted() {
    this.nCodOp = this.$route.params.nCodOp;
    this.cnpj = this.$route.params.cnpj;
    this.getManadRubricas(this.nCodOp, this.cnpj);
    this.getAliqAtualizadas(this.nCodOp, this.cnpj);
  },

  methods: {
    async getManadRubricas() {
      this.loadingSpinner = true;
      await inssService
        .getManadRubricas(this.nCodOp, this.cnpj)
        .then((response) => {
          // this.loadingSpinner = false;
          if (response.status == 200) {
            this.filesSuccessAdded = response.data.dados;
            this.ListaRubricas = response.data.dados;
            // this.nomeCliente = this.getCliente(this.nCodOp);
            // this.NomeContribuinte = this.nomeCliente;
            // this.isLoaded = true;
            this.getCliente(this.nCodOp, this.cnpj);
          }
          this.hasFilesSended = this.filesSuccessAdded.length > 0;
        })
        .catch((error) => {
          // return HelperService.callMakeToast(error, "error");
          this.$toast.open({ message: error, type: "error", duration: 2000 });
        });
      this.loadingSpinner = false;
    },

    async getCliente() {
      await inssService
        .getCliente(this.nCodOp, this.cnpj)
        .then((response) => {
          this.loadingSpinner = false;
          if (response.status == 200) {
            this.filesSuccessAdded = response.data.dados;
            this.NomeContribuinte = response.data.dados;
            // this.isLoaded = true;
          }
          this.hasFilesSended = this.filesSuccessAdded.length > 0;
        })
        .catch((error) => {
          // return HelperService.callMakeToast(error, "error");
          this.$toast.open({ message: error, type: "error", duration: 2000 });
        });
    },

    onRowSelected(items) {
      this.selected = items;
      this.ListaAtualizaEsocial = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
      this.ListaAtualizaEsocial = this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
      this.ListaAtualizaEsocial = [];
    },

    async atualizarEsocial() {
      this.loadingSpinner = true;
      await this.getAliqAtualizadas(this.nCodOp, this.cnpj);

      let aliquotas = {
        NCodOp: this.$route.params.nCodOp,
        AliquotaInss: this.aliquotas.AliquotaInss,
        AliquotaS5: this.aliquotas.AliquotaS5,
        AliquotaRAT: this.aliquotas.AliquotaRAT,
        Esocial: this.ListaAtualizaEsocial,
        AnoCorte: this.anoCorte,
        Cnpj: this.cnpj,
      };
      console.log("aliq atualizarESocial - ", aliquotas);
      this.processarCalculo(aliquotas);
    },

    async processarCalculo(aliquotas) {
      this.loadingSpinner = true;
      await UploadInssService.processarCalcInss(aliquotas, this.cnpj)
        .then((response) => {
          if (response.status == 200) {
            this.loadingSpinner = true;
            this.getManadRubricas(this.nCodOp, this.cnpj);
            this.atualizaPagina();
            this.showMsgBoxTwo();
          }
        })
        .catch((error) => {
          // return HelperService.callMakeToast(error, "error");
          this.$toast.open({ message: error, type: "error", duration: 2000 });
        });
      this.loadingSpinner = false;
    },

    atualizaPagina() {
      this.reloadListCalc = true;
      this.reloadRAT = true;
    },

    showMsgBoxTwo() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxOk("Processo realizado com sucesso!", {
          title: "Confirmação",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          // An error occurred
        });
    },

    async getAliqAtualizadas() {
      console.log("getAliqAtualizadas");
      await inssService
        .getCalculoProcessado(this.nCodOp, this.cnpj)
        .then((response) => {
          if (response.status == 200 && response.data.dados.length > 0) {
            if (response.data.dados[0].s5 !== null) {
              this.aliquotas.AliquotaS5 =
                response.data.dados[0].s5.toLocaleString();
              this.aliquotas.AliquotaRAT =
                response.data.dados[0].rat.toLocaleString();
              this.aliquotas.AliquotaInss =
                response.data.dados[0].inss.toLocaleString();
            }
          }
          console.log("aliquotas mounted: ", this.aliquotas);
        })
        .catch((error) => {
          console.log("error:", error);
          return HelperService.callMakeToast(error, "error");
        });
      this.loadingSpinner = false;
    },

    async verificarDuplicidade() {
      this.loadingSpinner = true;
      await inssService
        .verificarDuplicidade(this.nCodOp, this.cnpj)
        .then((response) => {
          if (response.status == 200) {
            console.log("response.data.dados-->", response.data.dados);
            if (response.data.dados) {
              this.$toast.open({
                message: "Pode existir valores duplicados",
                type: "warning",
                duration: 5000,
              });
            } else {
              this.$toast.open({
                message: "Não foram encontrados valores duplicados",
                type: "success",
                duration: 5000,
              });
            }
          }
        })
        .catch((error) => {
          console.log("error:", error);
          return HelperService.callMakeToast(error, "error");
        });
      this.loadingSpinner = false;
    },
  },
};
</script>

<style scoped>
.btnSelect {
  margin: 0 15px;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1440px;
}
</style>
