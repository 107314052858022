<template>
  <b-overlay :show="loadingSpinner" rounded="sm" opacity="1.6" spinner-small spinner-variant="secondary">
    <div class="main">
      <div>
        <button class="btn-exportar" @click="Exportar()" download>
          <strong> Relatorio inicial </strong>
        </button>

        <button class="btn-exportar" @click="ExportarCSV()" download>
          <strong> Relatorio CSV </strong>
        </button>

        <button class="btn-exportar" @click="ExportarCompleto()" download>
          <strong> Relatorio completo</strong>
        </button>

        <!-- <button class="btn-exportar" @click="ExportarCompleto(nivel2)" download>
        <strong> Relatorio mes a mes nivel 2</strong>
      </button>
      <button class="btn-exportar" @click="ExportarCompleto(nivel3)" download>
        <strong> Relatorio mes a mes nivel 3</strong>
      </button> -->
      </div>
    </div>
  </b-overlay>
</template>

<script>

import RelatorioService from "../services/relatorio.service";
import inssService from "../services/teses/inss.service";


export default {
  name: "Relatorios",

  components: {
    inssService,
  },

  props: ['niveisRelatorios'],

  data() {
    return {
      nCodOp: null,
      nomeCliente: null,
      dia: null,
      mes: null,
      ano: null,
      hora: null,
      minuto: null,
      segundo: null,
      concat: null,
      loadingSpinner: false,
      dataCompleta: null,
      // nivel1: 1,
      // nivel2: 2,
      // nivel3: 3,
      niveis: [],
      cnpj: null,
    }
  },

  mounted() {
    this.nCodOp = this.$route.params.nCodOp;
    this.cnpj = this.$route.params.cnpj;
  },

  methods: {
    async Exportar() {
      this.loadingSpinner = true;
      this.getCliente();
      var dataAtual = new Date;
      this.dia = dataAtual.getDate();
      this.mes = dataAtual.getMonth() + 1;
      this.ano = dataAtual.getFullYear();
      this.hora = dataAtual.getHours();
      this.minuto = dataAtual.getMinutes();
      this.segundo = dataAtual.getSeconds();

      this.dataCompleta = this.dia + "/" + this.mes + "/" + this.ano + "/" + "_" + this.hora + ":" + this.minuto + ":" + this.segundo;

      await RelatorioService.Exportar(this.nCodOp, this.cnpj).then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        this.concat = "Relatório Primeira Análise_" + this.nomeCliente + "_" + this.dataCompleta;
        // link.setAttribute("download", "NovoRelatorio.xlsx");
        link.setAttribute("download", this.concat + ".xlsx");
        document.body.appendChild(link);
        link.click();
      });
      this.loadingSpinner = false;
    },

    async ExportarCompleto() {
      this.loadingSpinner = true;
      this.getCliente();
      this.niveis = this.niveisRelatorios;
      if (this.niveis.length == 0) {
        this.showMsgBox();

      } else {
        await RelatorioService.ExportarCompleto(this.nCodOp, this.niveis, this.cnpj).then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          this.MontaData();
          this.concat = "Relatório Discriminado_" + this.nomeCliente + "_" + this.dataCompleta;
          link.setAttribute("download", this.concat + ".xlsx");
          document.body.appendChild(link);
          link.click();
        });
      }
      this.loadingSpinner = false;
    },

    async ExportarCSV() {
      console.log("export CSV");
      this.loadingSpinner = true;
      this.getCliente();
      var dataAtual = new Date;
      this.dia = dataAtual.getDate();
      this.mes = dataAtual.getMonth() + 1;
      this.ano = dataAtual.getFullYear();
      this.hora = dataAtual.getHours();
      this.minuto = dataAtual.getMinutes();
      this.segundo = dataAtual.getSeconds();

      this.dataCompleta = this.dia + "/" + this.mes + "/" + this.ano + "/" + "_" + this.hora + ":" + this.minuto + ":" + this.segundo;

      await RelatorioService.ExportarCSV(this.nCodOp, this.cnpj).then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type:
              "text/csv",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        this.concat = "Relatório Primeira Análise_" + this.nomeCliente + "_" + this.dataCompleta;
        // link.setAttribute("download", "NovoRelatorio.xlsx");
        link.setAttribute("download", this.concat + ".csv");
        document.body.appendChild(link);
        link.click();
      });
      this.loadingSpinner = false;
    },

    async ExportarCSV2() {
      console.log("export CSV2");
      this.loadingSpinner = true;
      gerarRelatorio.value = true;
      getCliente();
      const dataCompleta = actualDate();

      const dadosRequest = await RelatorioService.ExportarCSV(
        store.getNCodOp,
        store.getCnpj
      );
      globalData.isLoadingGlobal = false;

      let url = "";
      if (dadosRequest.sucesso == 200) {
        url = URL.createObjectURL(
          new Blob([dadosRequest.dados], {
            type: "text/csv",
          })
        );
      } else {
        const error = dadosRequest.mensagem;
      }

      const link = document.createElement("a");
      link.href = url;
      const concat = `Relatório Primeira Análise_${store.getNomeCliente}_${dataCompleta}`;
      link.setAttribute("download", concat + ".csv");
      document.body.appendChild(link);
      link.click();
      isDropdownVisible.value = false;
      globalData.isLoadingGlobal = false;
      gerarRelatorio.value = false;
    },

    MontaData() {
      var dataAtual = new Date;
      this.dia = dataAtual.getDate();
      this.mes = dataAtual.getMonth() + 1;
      this.ano = dataAtual.getFullYear();
      this.hora = dataAtual.getHours();
      this.minuto = dataAtual.getMinutes();
      this.segundo = dataAtual.getSeconds();
      this.dataCompleta = this.dia + "/" + this.mes + "/" + this.ano + "/" + "_" + this.hora + ":" + this.minuto + ":" + this.segundo;
    },

    async getCliente() {
      await inssService.getCliente(this.nCodOp, this.cnpj)
        .then((response) => {
          if (response.status == 200) {
            this.filesSuccessAdded = response.data.dados;
            this.nomeCliente = response.data.dados;
            // this.isLoaded = true;
          }
          this.hasFilesSended = this.filesSuccessAdded.length > 0;
        })
        .catch((error) => {
          // return HelperService.callMakeToast(error, "error");
          this.$toast.open({ message: error, type: 'error', duration: 2000 });
        });
    },

    showMsgBox() {
      this.box = ''
      this.$bvModal.msgBoxOk('Selecione pelo menos um nivel', {
        title: 'Aviso',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'alert',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then(value => {
          this.box = value
        })
        .catch(err => {
          // An error occurred
        })
    }

  },
};
</script>
<style scoped>
.main {
  width: 90%;
  margin-left: 5%;
  background: #fff;
}

.btn-exportar {
  border: 1px solid #233873;
  color: #233873;
  margin: 3% 3%;
}
</style>
