<template>
 <b-overlay :show="loadingSpinner" rounded="sm" opacity="0.6" spinner-small spinner-variant="secondary">
<div class="container-fluid jumbotron flex-container">
 

  <b-card id="aliquotas" class="flex-item" v-for="(rat,index) in getListRat" :key="index">
            <h4 class="text-center">Alíquota de RAT {{rat.anoRAT}}</h4>
          <b-form class="d-flex justify-content-around form-inline" >
            <div class="aliquotas">
              <label for="rat">RAT:</label>
              <input class="text-center" type="text" id="rat" v-model="rat.aliquotaRAT" />
            </div>
          </b-form>
        </b-card>



        <!-- <b-card id="aliquotas" class="flex-item">
            <h4 class="text-center">Alíquota de RAT {{this.data1}}</h4>
          <b-form class="d-flex justify-content-around form-inline" >
            <div class="aliquotas">
              <label for="rat">RAT:</label>
              <input class="text-center" type="text" id="rat" v-model="aliquotasRAT1.AliquotaRAT" />
            </div>
          </b-form>
        </b-card>

        <br />

        <b-card id="aliquotas" class="flex-item">
            <h4 class="text-center">Alíquota de RAT {{this.data2}}</h4>
          <b-form class="d-flex justify-content-around form-inline" >
            <div class="aliquotas">
              <label for="rat">RAT:</label>
              <input class="text-center" type="text" id="rat" v-model="aliquotasRAT2.AliquotaRAT" />
            </div>
          </b-form>
        </b-card>

        <br />

        <b-card id="aliquotas" class="flex-item">
            <h4 class="text-center">Alíquota de RAT {{this.data3}}</h4>
          <b-form class="d-flex justify-content-around form-inline" >
            <div class="aliquotas">
              <label for="rat">RAT:</label>
              <input class="text-center" type="text" id="rat" v-model="aliquotasRAT3.AliquotaRAT" />
            </div>
          </b-form>
        </b-card>

        <br />

        <b-card id="aliquotas" class="flex-item">
            <h4 class="text-center">Alíquota de RAT {{this.data4}}</h4>
          <b-form class="d-flex justify-content-around form-inline" >
            <div class="aliquotas">
              <label for="rat">RAT:</label>
              <input class="text-center" type="text" id="rat" v-model="aliquotasRAT4.AliquotaRAT" />
            </div>
          </b-form>
        </b-card>

        <br/>

        <b-card id="aliquotas" class="flex-item">
            <h4 class="text-center">Alíquota de RAT {{this.data5}}</h4>
          <b-form class="d-flex justify-content-around form-inline" >
            <div class="aliquotas">
              <label for="rat">RAT:</label>
              <input class="text-center" type="text" id="rat" v-model="aliquotasRAT5.AliquotaRAT" />
            </div>
          </b-form>
        </b-card> 

        <br />
        
        <b-card id="aliquotas" class="flex-item">
            <h4 class="text-center">Alíquota de RAT {{this.data6}}</h4>
          <b-form class="d-flex justify-content-around form-inline" >
            <div class="aliquotas">
              <label for="rat">RAT:</label>
              <input class="text-center" type="text" id="rat" v-model="aliquotasRAT6.AliquotaRAT" />
            </div>
          </b-form>
        </b-card>       -->

        <div class="flex-item2">
              <p></p>
              <button type="submit" class="btn-sm btn-outline-blue" @click="atualizarRAT()">Atualizar RAT</button>
        </div>

</div>
</b-overlay>

       
</template>

<script>

import inssService from "@/services/teses/inss.service";

export default {
    name: "Aliquotas_Inss",  
    components: {

    },

    props:{
      ActivateGetRAT: {
        type:Boolean,
        required: true,
        default: false
      }
    },   

    watch:{
      ActivateGetRAT(newValue){
        if(newValue){
          this.getRat(this.nCodOp, this.cnpj)
          this.$emit("resetReloadRAT", false)
        }
      }
    },

    data(){
    return{
        nCodOp: null,
        loadingSpinner: false,        
        getListRat: [],
        cnpj: null,
    };
    },
   
    mounted() {
      this.nCodOp = this.$route.params.nCodOp;
      this.cnpj = this.$route.params.cnpj;
      this.getRat(this.nCodOp, this.cnpj);
      
    },  

    methods:{
        atualizarRAT() {
        this.loadingSpinner = true;
          inssService.atualizaRAT(this.getListRat).then((response) => {
          if (response.status == 200) {
            this.loadingSpinner = false;
            this.showMsgBoxTwo();
          }
        })
      },

      async getRat(){
        this.loadingSpinner = true;
        await inssService.getRat(this.nCodOp, this.cnpj)
        .then((response) => {
          if(response.status == 200){
            this.getListRat = response.data.dados;
            // console.log("response: ",response.data.dados);
            //console.log("getListRat: ",this.getListRat);
          }
        }).catch((error) => {
          // return HelperService.callMakeToast(error, "error");
          this.$toast.open({message: error, type: 'error', duration: 2000});
        });
        this.loadingSpinner = false;
      },

        showMsgBoxTwo() {
        this.boxTwo = ''
        this.$bvModal.msgBoxOk('Processo realizado com sucesso!', {
          title: 'Confirmação',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
          .then(value => {
            this.boxTwo = value
          })
          .catch(err => {
            // An error occurred
          })    
       },
    
    }
}

</script>

<style scoped>
 /* ids */
 #aliquotas {
    border: 0.2em solid rgba(61, 169, 56, 0.8);
  }
  
  /* classes */
  .aliquotas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
  }

  .flex-container{
    display: flex;
    flex-flow: row wrap;  
    justify-content: center;
  }

  .flex-item {
display: flex;
margin-right: 15px;
margin-top: 15px;
flex-direction: column;
}

.flex-item2{
  display: flex;
flex-direction: column;
width: 50%;
margin-right: 15px;
}

</style>