<template>
  <section id="inss">
    <!-- Campo para definir as aliquotas -->
    <b-overlay
      :show="loadingSpinner"
      rounded="sm"
      opacity="0.6"
      spinner-small
      spinner-variant="secondary"
    >
      <div class="container-fluid jumbotron">
        <h4 class="text-center">Aliquotas</h4>
        <b-card id="aliquotas" class="cardAliquotas">
          <b-form
            class="d-flex justify-content-around form-inline"
            @submit.prevent="submit"
          >
            <div class="aliquotas">
              <label for="inss">INSS:</label>
              <input
                class="text-center lbAliquota"
                type="text"
                id="inss"
                v-model="aliquotas.AliquotaInss"
              />
            </div>
            <div class="aliquotas">
              <label for="s5">S5: </label>
              <input
                class="text-center"
                type="text"
                id="s5"
                v-model="aliquotas.AliquotaS5"
              />
            </div>
            <div class="aliquotas">
              <label for="rat">RAT:</label>
              <input
                class="text-center"
                type="text"
                id="rat"
                v-model="aliquotas.AliquotaRAT"
              />
            </div>
            <div class="aliquotas">
              <button
                type="button"
                @click="hasCalculation ? updateOrReset() : submit()"
                class="btn-sm btn-outline-blue"
              >
                Processar
              </button>
            </div>
          </b-form>
        </b-card>

        <!-- Botão salvar -->
        <div
          v-if="tables"
          class="text-center"
          style="margin-top: 5%; margin-bottom: 0"
        >
          <div style="display: flex; flex-direction: row-reverse">
            <button class="btn btn-outline-blue" v-b-toggle.relatorios>
              Relatorios
            </button>
            <b-collapse id="relatorios">
              <Relatorios :niveisRelatorios="selectNivel"></Relatorios>
            </b-collapse>
            <button
              class="btn btn-outline-blue"
              @click="ProcessarCompensacao()"
            >
              Enviar para compensação
            </button>
          </div>
        </div>
      </div>

      <b-tabs class="titleColor">
        <!-- Tabela nível 1 -->
        <b-tab active class="pt-2">
          <template #title>
            Verbas A
            <span class="badge badge-pill badge-primary">
              <span class="sr-only">Nivel 1</span>
            </span>
          </template>
          <b-overlay
            v-if="tables"
            :show="loadingTables"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <div class="container-fluid jumbotron">
              <label for="nivel1" class="labelNivel"
                >Verbas A<input
                  type="checkbox"
                  id="nivel1"
                  class="nivel"
                  value="1"
                  v-model="selectNivel"
              /></label>

              <table class="table table-responsive-xl" style="cursor: pointer">
                <thead class="bg-dark text-light">
                  <tr>
                    <th><strong>Código rúbrica</strong></th>
                    <th><strong>Descrição da verba</strong></th>
                    <th><strong>Valor INSS</strong></th>
                    <th><strong>Valor RAT</strong></th>
                    <th><strong>Valor S5</strong></th>
                    <th><strong>Valor total</strong></th>
                    <th><strong>Açoes</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in niveis.nivel1"
                    :key="index"
                    v-on:click="GetPeriod(item)"
                  >
                    <td>
                      {{ item.codigoRubrica }}
                    </td>
                    <td>
                      {{ item.descricao }}
                    </td>
                    <td>
                      {{ item.valorInss }}
                    </td>
                    <td>
                      {{ item.valorRat }}
                    </td>
                    <td>
                      {{ item.valorS5 }}
                    </td>
                    <td>
                      {{ item.valorTotal }}
                    </td>
                    <td>
                      <div class="divNiveis">
                        <button
                          class="btn btn-sm btn-outline-blue"
                          @click="changeNivel(item, (nivel = 2))"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Verbas B </strong>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-green"
                          @click="changeNivel(item, (nivel = 3))"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Verbas C </strong>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-danger"
                          @click="DeletefromNivel(item)"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Excluir </strong>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </b-tab>

        <!-- Tabela nível 2 -->
        <b-tab class="pt-2">
          <template #title>
            Verbas B
            <span class="badge badge-pill badge-primary">
              <span class="sr-only">Verbas B</span>
            </span>
          </template>
          <b-overlay
            v-if="tables"
            :show="loadingTables"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <div class="container-fluid jumbotron">
              <label for="nivel2" class="labelNivel"
                >Verbas B
                <input
                  type="checkbox"
                  id="nivel2"
                  class="nivel"
                  value="2"
                  v-model="selectNivel"
              /></label>

              <table class="table table-responsive-xl" style="cursor: pointer">
                <thead class="bg-dark text-light">
                  <tr>
                    <th><strong>Código rúbrica</strong></th>
                    <th><strong>Descrição da verba</strong></th>
                    <th><strong>Valor INSS</strong></th>
                    <th><strong>Valor RAT</strong></th>
                    <th><strong>Valor S5</strong></th>
                    <th><strong>Valor total</strong></th>
                    <th><strong>Açoes</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in niveis.nivel2"
                    :key="index"
                    v-on:click="GetPeriod(item)"
                  >
                    <td class="ocultarId">
                      {{ item.nivelId }}
                    </td>
                    <td>
                      {{ item.codigoRubrica }}
                    </td>
                    <td>
                      {{ item.descricao }}
                    </td>
                    <td>
                      {{ item.valorInss }}
                    </td>
                    <td>
                      {{ item.valorRat }}
                    </td>
                    <td>
                      {{ item.valorS5 }}
                    </td>
                    <td>
                      {{ item.valorTotal }}
                    </td>
                    <td>
                      <div class="divNiveis">
                        <button
                          class="btn btn-sm btn-outline-blue"
                          @click="changeNivel(item, (nivel = 1))"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Verbas A </strong>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-green"
                          @click="changeNivel(item, (nivel = 3))"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Verbas C </strong>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-danger"
                          @click="DeletefromNivel(item)"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Excluir </strong>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </b-tab>

        <!-- Tabela nível 3 -->
        <b-tab class="pt-2">
          <template #title>
            Verbas C
            <span class="badge badge-pill badge-primary">
              <span class="sr-only">Verbas C</span>
            </span>
          </template>
          <b-overlay
            v-if="tables"
            :show="loadingTables"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <div class="container-fluid jumbotron">
              <label for="nivel3" class="labelNivel"
                >Verbas C
                <input
                  type="checkbox"
                  id="nivel3"
                  class="nivel"
                  value="3"
                  v-model="selectNivel"
              /></label>

              <table class="table table-responsive-xl" style="cursor: pointer">
                <thead class="bg-dark text-light">
                  <tr>
                    <th><strong>Código rúbrica</strong></th>
                    <th><strong>Descrição da verba</strong></th>
                    <th><strong>Valor INSS</strong></th>
                    <th><strong>Valor RAT</strong></th>
                    <th><strong>Valor S5</strong></th>
                    <th><strong>Valor total</strong></th>
                    <th><strong>Açoes</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in niveis.nivel3"
                    :key="index"
                    v-on:click="GetPeriod(item)"
                  >
                    <td>
                      {{ item.codigoRubrica }}
                    </td>
                    <td>
                      {{ item.descricao }}
                    </td>
                    <td>
                      {{ item.valorInss }}
                    </td>
                    <td>
                      {{ item.valorRat }}
                    </td>
                    <td>
                      {{ item.valorS5 }}
                    </td>
                    <td>
                      {{ item.valorTotal }}
                    </td>
                    <td>
                      <div class="divNiveis">
                        <button
                          class="btn btn-sm btn-outline-blue"
                          @click="changeNivel(item, (nivel = 1))"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Verbas A </strong>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-green"
                          @click="changeNivel(item, (nivel = 2))"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Verbas B </strong>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-danger"
                          @click="DeletefromNivel(item)"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Excluir </strong>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </b-tab>

        <!-- outras entidades -->
        <b-tab class="pt-2">
          <template #title>
            Verbas D - Outras Entidades
            <span class="badge badge-pill badge-primary">
              <span class="sr-only">Verbas D - Outras Entidades</span>
            </span>
          </template>
          <!-- Tabela Outras Entidades -->
          <b-overlay
            v-if="tables"
            :show="loadingTables"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <div class="container-fluid jumbotron">
              <!-- <h4 class="text-center">Outras Entidades</h4> -->

              <label for="nivel4" class="labelNivel"
                >Verbas D - Outras Entidades
                <input
                  type="checkbox"
                  id="nivel4"
                  class="nivel"
                  value="4"
                  v-model="selectNivel"
              /></label>

              <br />
              <div class="btnOE">
                <label class="labelSelect">Data de Corte</label>
                <b-input
                  class="inputSelect"
                  placeholder="MÊS/ANO"
                  v-model="anoCorte"
                ></b-input>
                <b-button class="btnSelect" variant="info" @click="ExcluirOE"
                  >Excluir periodos</b-button
                >
              </div>

              <table class="table table-responsive-sm" style="cursor: pointer">
                <thead class="bg-dark text-light">
                  <th>Competencia</th>
                  <th>Valor Pago</th>
                  <th>Valor Devido</th>
                  <th>Valor Credito</th>
                  <th><strong>Açoes</strong></th>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in niveis.outrasEntidades"
                    :key="index"
                  >
                    <td>
                      {{ item.descricao }}
                    </td>
                    <td>
                      {{ item.valorInss }}
                    </td>
                    <td>
                      {{ item.valorS5 }}
                    </td>
                    <td>
                      {{ item.valorRat }}
                    </td>
                    <button
                      class="btn btn-sm btn-outline-danger"
                      @click="DeletefromNivel(item)"
                      v-if="item.descricao != 'TOTAIS:'"
                    >
                      <strong> Excluir </strong>
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </b-tab>

        <!-- rubricas excluidas -->
        <b-tab class="pt-2">
          <template #title>
            Rubricas Excluidas
            <span class="badge badge-pill badge-primary">
              <span class="sr-only">Rubricas Excluidas</span>
            </span>
          </template>
          <!-- Tabela Rubricas Excluidas -->
          <b-overlay
            v-if="tables"
            :show="loadingTables"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <div class="container-fluid jumbotron">
              <table class="table table-responsive-xl" style="cursor: pointer">
                <thead class="bg-dark text-light">
                  <tr>
                    <th><strong>Código rúbrica</strong></th>
                    <th><strong>Descrição da verba</strong></th>
                    <th><strong>Valor INSS</strong></th>
                    <th><strong>Valor total</strong></th>
                    <th><strong>Recuperar Rubricas</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in niveis.rubricasExcluidas"
                    :key="index"
                  >
                    <td>
                      {{ item.codigoRubrica }}
                    </td>
                    <td>
                      {{ item.descricao }}
                    </td>
                    <td>
                      {{ item.valorInss }}
                    </td>
                    <td>
                      {{ item.valorTotal }}
                    </td>
                    <td>
                      <div class="divNiveis">
                        <button
                          class="btn btn-sm btn-outline-green"
                          @click="recuperaRubrica(item, (nivel = 1))"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Verbas A </strong>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-green"
                          @click="recuperaRubrica(item, (nivel = 2))"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Verbas B </strong>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-green"
                          @click="recuperaRubrica(item, (nivel = 3))"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Verbas C </strong>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-green"
                          @click="recuperaRubrica(item, (nivel = 4))"
                          v-if="item.descricao != 'TOTAIS:'"
                        >
                          <strong> Verbas D - OE </strong>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </b-tab>

        <!-- excluir periodos -->
        <b-tab class="pt-2">
          <template #title>
            Excluir Periodos
            <span class="badge badge-pill badge-primary">
              <span class="sr-only">Excluir Periodos</span>
            </span>
          </template>
          <!-- Tabela Periodos -->
          <b-overlay
            v-if="tables"
            :show="loadingSpinner"
            rounded="sm"
            opacity="0.6"
            spinner-small
            spinner-variant="secondary"
          >
            <div class="container-fluid jumbotron">
              <table class="table table-responsive-sm" style="cursor: pointer">
                <thead class="bg-dark text-light">
                  <th>Competencia</th>
                  <th>Valor Rubrica</th>
                  <th><strong>Açoes</strong></th>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in listaPeriodos" :key="index">
                    <td>
                      {{ item.dt_comp }}
                    </td>
                    <td>
                      {{ formatMoney(item.vlr_rubr) }}
                    </td>
                    <button
                      class="btn btn-sm btn-outline-danger"
                      @click="DeleteAllPeriodo(item)"
                      v-if="item.descricao != 'TOTAIS:'"
                    >
                      <strong> Excluir </strong>
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </b-tab>

        <!-- recuperar periodos -->
        <b-tab class="pt-2">
          <template #title>
            Recuperar todo Periodo
            <span class="badge badge-pill badge-primary">
              <span class="sr-only">Recuperar todo Periodo</span>
            </span>
          </template>
          <!-- Tabela Periodos -->
          <b-overlay
            v-if="tables"
            :show="loadingSpinner"
            rounded="sm"
            opacity="0.6"
            spinner-small
            spinner-variant="secondary"
          >
            <div class="container-fluid jumbotron">
              <table class="table table-responsive-sm" style="cursor: pointer">
                <thead class="bg-dark text-light">
                  <th>Competencia</th>
                  <th>Valor Rubrica</th>
                  <th><strong>Açoes</strong></th>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in listaPeriodosRecuperar"
                    :key="index"
                  >
                    <td>
                      {{ item.dt_comp }}
                    </td>
                    <td>
                      {{ formatMoney(item.vlr_rubr) }}
                    </td>
                    <button
                      class="btn btn-sm btn-outline-green"
                      @click="RecoverAllPeriodo(item)"
                      v-if="item.descricao != 'TOTAIS:'"
                    >
                      <strong> Recuperar </strong>
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </b-tab>
      </b-tabs>
    </b-overlay>

    <!-- periodos -->
    <b-modal
      :title="periods"
      size="xl"
      id="modal-exclusao-period"
      ref="modal-exclusao-period"
      scrollable
    >
      <b-tabs class="titleColor">
        <b-tab active class="pt-4">
          <template #title>
            Periodos
            <span class="badge badge-pill badge-primary">
              {{ listPeriod.length }}
              <span class="sr-only">Periodos</span>
            </span>
          </template>
          <b-overlay
            :show="loadingSpinner"
            rounded="sm"
            opacity="0.6"
            spinner-small
            spinner-variant="info"
          >
            <table class="table">
              <thead>
                <tr>
                  <th>Periodo</th>
                  <th>Codigo Rubrica</th>
                  <th>Descrição Rubrica</th>
                  <th>Valor da Rubrica</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listPeriod" :key="index">
                  <td>{{ item.dt_comp }}</td>
                  <td>{{ item.cod_rubr }}</td>
                  <td>{{ item.desc_rubrica }}</td>
                  <td>{{ formatMoney(item.vlr_rubr) }}</td>
                  <div class="divPeriod">
                    <button
                      class="btn btn-sm btn-outline-danger"
                      @click="DeletePeriod(item)"
                      v-if="item.cod_reg_trab != 'TOTAIS:'"
                    >
                      <strong> Excluir </strong>
                    </button>
                  </div>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </b-tab>

        <b-tab class="pt-4" lazy>
          <template #title>
            Removidos
            <span class="badge badge-pill badge-primary">
              {{ listPeriodexcluded.length }}
              <span class="sr-only">Removidos</span>
            </span>
          </template>
          <b-overlay
            :show="loadingSpinner"
            rounded="sm"
            opacity="0.6"
            spinner-small
            spinner-variant="info"
          >
            <table class="table">
              <thead>
                <tr>
                  <th>Periodo</th>
                  <th>Codigo Rubrica</th>
                  <th>Descrição Rubrica</th>
                  <th>Valor da Rubrica</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listPeriodexcluded" :key="index">
                  <td>{{ item.dt_comp }}</td>
                  <td>{{ item.cod_rubr }}</td>
                  <td>{{ item.desc_rubrica }}</td>
                  <td>{{ formatMoney(item.vlr_rubr) }}</td>
                  <div class="divPeriod">
                    <button
                      class="btn btn-sm btn-outline-green"
                      @click="RecoverPeriod(item)"
                      v-if="item.cod_reg_trab != 'TOTAIS:'"
                    >
                      <strong> Recuperar Período </strong>
                    </button>
                  </div>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </b-tab>
      </b-tabs>

      <template #modal-footer>
        <!-- <b-button variant="btn btn-outline-danger" @click="$bvModal.hide('modal-exclusao-period')"> -->
        <b-button variant="btn btn-outline-danger" @click="FecharModal()">
          Cancelar
        </b-button>
      </template>
    </b-modal>

    <b-modal
      :title="periods"
      size="sm"
      id="modal-qntdGuias"
      ref="modal-qntdGuias"
    >
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="quantidadeGuias">Quantidade de Guias:</label>
          <input
            type="number"
            id="quantidadeGuias"
            v-model="quantidadeGuias"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label for="dataVencimento">Data de Vencimento:</label>
          <input
            type="date"
            id="dataVencimento"
            v-model="dataVencimento"
            class="form-control"
            required
          />
        </div>
        <button
          type="submit"
          class="btn btn-primary"
          :disabled="loadingSpinner"
        >
          {{ loadingSpinner ? "Aguarde..." : "Salvar" }}
        </button>
      </form>
      <template #modal-footer>
        <b-button
          variant="btn btn-outline-danger"
          @click="FecharModalQntGuia()"
          :disabled="loadingSpinner"
        >
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import ImportManadForm from "@/Components/Teses/Inss/ImportManadForm.vue";
import UploadComponentInss from "@/Components/UploadComponent/UploadComponentInss.vue";
import inssService from "@/services/teses/inss.service";
import UploadInssService from "@/services/uploadInss.service.js";
import Relatorios from "@/views/Relatorios.vue";
import helperService from "../../../services/helper.service";

export default {
  name: "Calculo_Inss",

  components: {
    ImportManadForm,
    UploadComponentInss,
    // RelatorioService,
    Relatorios,
  },

  props: { ActivateGetCalculo: Boolean },

  data() {
    return {
      nCodOp: null,
      loadingSpinner: false,
      loadingTables: false,
      showUploadComponent: true,
      dataImportManad: {},
      teste: [],
      aliquotas: {
        AliquotaInss: "20",
        AliquotaS5: "5,8",
        AliquotaRAT: "1,5",
      },
      tables: false,
      niveis: {
        nivel1: [],
        nivel2: [],
        nivel3: [],
        outrasEntidades: [],
        rubricasExcluidas: [],
      },

      sortBy: {
        nivel1: "codigoRubrica",
        nivel2: "codigoRubrica",
        nivel3: "codigoRubrica",
      },
      sortDesc: { nivel1: false, nivel2: false, nivel3: false },
      uploaded: null,
      relatorioInicial: [],
      small: true,
      contLista: 0,
      desTotais: "",
      boxTwo: "",
      prefeitura: false,
      selectNivel: [],
      cnpj: null,
      listPeriod: [],
      listPeriodexcluded: [],
      periods: "",
      listaPeriodos: [],
      listaPeriodosRecuperar: [],
      anoCorte: "",
      hasCalculation: false,
      quantidadeGuias: 0,
      dataVencimento: null,
    };
  },

  mounted() {
    this.nCodOp = this.$route.params.nCodOp;
    this.cnpj = this.$route.params.cnpj;
    this.getCalculoProcessado(this.nCodOp, this.cnpj);
    this.getPrefeitura(this.nCodOp);
  },

  methods: {
    async getCalculoProcessado() {
      this.loadingSpinner = true;
      this.niveis = {
        nivel1: [],
        nivel2: [],
        nivel3: [],
        outrasEntidades: [],
        rubricasExcluidas: [],
      };
      await inssService
        .getCalculoProcessado(this.nCodOp, this.cnpj)
        .then((response) => {
          if (response.status == 200) {
            this.filesSuccessAdded = response.data.dados;
            this.relatorioInicial = response.data.dados;
            console.log("res inicial - ", response.data.dados);

            this.contLista = this.relatorioInicial.length - 1;
            this.desTotais = this.relatorioInicial[this.contLista].descricao;
            this.tables = true;
            if (response.data.dados[0].s5 !== null) {
              this.aliquotas.AliquotaS5 =
                response.data.dados[0].s5.toLocaleString();
              this.aliquotas.AliquotaRAT =
                response.data.dados[0].rat.toLocaleString();
              this.aliquotas.AliquotaInss =
                response.data.dados[0].inss.toLocaleString();
              this.hasCalculation = true;
            }
            this.montaNiveis();
            this.getAllPeriodos();
          }
          this.hasFilesSended = this.filesSuccessAdded.length > 0;
        })
        .catch((error) => {
          console.log("error:", error);
          // return helperService.callMakeToast(error, "error");
          return helperService.callMakeToast("Sem calculo.", "error");
        });
      console.log("getCalculoProcessado fim");
      this.loadingSpinner = false;
    },

    async getAllPeriodos() {
      this.loadingSpinner = true;
      if (this.listaPeriodos.length == 0) {
        await inssService
          .getAllPeriodos(this.nCodOp, this.cnpj)
          .then((response) => {
            if (response.status == 200) {
              console.log("response.data.dados-->", response.data.dados);
              response.data.dados.forEach((item) => {
                if (item.periodo_Excluido == 0) {
                  this.listaPeriodos.push(item);
                } else if (item.periodo_Excluido == 1) {
                  this.listaPeriodosRecuperar.push(item);
                }
              });
              // this.listaPeriodos = response.data.dados;
            }
          })
          .catch((error) => {
            console.log("error:", error);
            return helperService.callMakeToast(error, "error");
          });
      }
      this.loadingSpinner = false;
    },

    async atualizaNivel(
      nivelId,
      novoNivel,
      cnpj,
      codigoRubrica,
      nCodOp,
      anoCorte
    ) {
      console.log("atualizaNivel");
      this.loadingSpinner = true;
      await inssService
        .atualizaNivel(
          nivelId,
          novoNivel,
          cnpj,
          codigoRubrica,
          nCodOp,
          anoCorte
        )
        .then((response) => {
          // this.loadingSpinner = false;
          if (response.status == 200) {
            this.filesSuccessAdded = response.data.dados;
            this.relatorioInicial = response.data.dados;
          }
          if (response.data.sucesso) {
            this.niveis = {
              nivel1: [],
              nivel2: [],
              nivel3: [],
              outrasEntidades: [],
              rubricasExcluidas: [],
            };
            console.log("chamou 1");
            this.getCalculoProcessado(this.nCodOp, this.cnpj);
          }
          // this.hasFilesSended = this.filesSuccessAdded.length > 0;
        })
        .catch((error) => {
          console.log("error:", error);
          return helperService.callMakeToast(error, "error");
        });
      // console.log("this.nCodOp:", this.nCodOp);
      this.loadingSpinner = false;
    },

    async getPrefeitura() {
      await inssService
        .getPrefeitura(this.nCodOp)
        .then((response) => {
          this.loadingSpinner = false;
          if (response.status == 200) {
            this.filesSuccessAdded = response.data.dados;
            this.prefeitura = response.data.dados;
            // this.isLoaded = true;
          }
          this.hasFilesSended = this.filesSuccessAdded.length > 0;
          // console.log("this.prefeitura: ",this.prefeitura);
          if (this.prefeitura) {
            (this.aliquotas.AliquotaS5 = "0,0"),
              (this.aliquotas.AliquotaRAT = "1,0");
          }
        })
        .catch((error) => {
          return helperService.callMakeToast(error, "error");
        });
    },

    async submit() {
      this.tables = true;
      this.loadingTables = true;
      this.loadingSpinner = true;
      let aliquotas = {
        NCodOp: this.$route.params.nCodOp,
        AliquotaInss: this.aliquotas.AliquotaInss,
        AliquotaS5: this.aliquotas.AliquotaS5,
        AliquotaRAT: this.aliquotas.AliquotaRAT,
        Cnpj: this.$route.params.cnpj,
        AnoCorte: this.anoCorte,
      };

      console.log("aliq calculo - ", aliquotas);
      await UploadInssService.processarCalcInss(aliquotas).then((response) => {
        this.niveis = {
          nivel1: [],
          nivel2: [],
          nivel3: [],
          outrasEntidades: [],
        };
        this.relatorioInicial = response.data.dados; // add para testar metodo igual
        // console.log("processarCalcInss response: ",response.data);
        this.montaNiveis();
        this.loadingTables = false;
        this.loadingSpinner = false;
        this.getAllPeriodos(0);
        this.showMsgBoxTwo();
      });
    },

    FecharModal() {
      this.$refs["modal-exclusao-period"].hide();
    },

    FecharModalQntGuia() {
      this.quantidadeGuias = 0;
      this.dataVencimento = null;
      this.$refs["modal-qntdGuias"].hide();
    },

    async ProcessarCompensacao() {
      this.loadingSpinner = true;
      console.log("cnpj", this.cnpj);
      console.log("nCodOp", this.nCodOp);

      this.$refs["modal-qntdGuias"].show();

      // await inssService
      //   .ProcessarCompensacao(this.nCodOp, this.cnpj)
      //   .then((response) => {
      //     if (response.status == 200) {
      //       console.log("Enviado para compensação");
      //     }
      //   })
      //   .catch((error) => {
      //     console.log("error: ", error);
      //     return helperService.callMakeToast(error, "error");
      //   });
      this.loadingSpinner = false;
    },

    async submitForm() {
      this.loadingSpinner = true;
      console.log("Quantidade de Guias:", this.quantidadeGuias);
      console.log("Data de Vencimento:", this.dataVencimento);

      await inssService
        .ProcessarCompensacao(
          this.nCodOp,
          this.cnpj,
          this.quantidadeGuias,
          this.dataVencimento
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("Enviado para compensação");
            this.quantidadeGuias = 0;
            this.dataVencimento = null;
            this.showMsgBoxTwo();
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          this.quantidadeGuias = 0;
          this.dataVencimento = null;
          return helperService.callMakeToast(error, "error");
        });

      // Fechar o modal após o envio do formulário
      this.loadingSpinner = false;
      this.$refs["modal-qntdGuias"].hide();
    },

    async changeNivel(obj, nivel) {
      event.stopPropagation();

      switch (obj.nivel) {
        case 1:
          await this.atualizaNivel(
            obj.nivelId,
            nivel,
            this.cnpj,
            obj.codigoRubrica,
            this.nCodOp,
            "attNivel"
          );
          console.log("obj", obj);
          break;
        case 2:
          this.loadingTables = true;
          await this.atualizaNivel(
            obj.nivelId,
            nivel,
            this.cnpj,
            obj.codigoRubrica,
            this.nCodOp,
            "attNivel"
          );
          console.log("obj", obj);
          break;
        case 3:
          await this.atualizaNivel(
            obj.nivelId,
            nivel,
            this.cnpj,
            obj.codigoRubrica,
            this.nCodOp,
            "attNivel"
          );
          console.log("obj", obj);
          break;
      }
      this.loadingTables = false;
    },

    recuperaRubrica(obj, nivel) {
      this.loadingTables = true;
      switch (nivel) {
        case 1:
          this.atualizaNivel(
            obj.nivelId,
            nivel,
            this.cnpj,
            obj.codigoRubrica,
            this.nCodOp,
            "RecoverRubrica"
          );
          break;
        case 2:
          this.atualizaNivel(
            obj.nivelId,
            nivel,
            this.cnpj,
            obj.codigoRubrica,
            this.nCodOp,
            "RecoverRubrica"
          );
          break;
        case 3:
          this.atualizaNivel(
            obj.nivelId,
            nivel,
            this.cnpj,
            obj.codigoRubrica,
            this.nCodOp,
            "RecoverRubrica"
          );
          break;
        case 4:
          this.atualizaNivel(
            obj.nivelId,
            nivel,
            this.cnpj,
            obj.codigoRubrica,
            this.nCodOp,
            "RecoverRubrica"
          );
          break;
      }
      this.loadingTables = false;
    },

    async GetPeriod(obj) {
      this.loadingSpinner = true;
      this.listPeriod = [];
      this.listPeriodexcluded = [];
      this.$refs["modal-exclusao-period"].show();
      await inssService
        .getPeriod(this.nCodOp, this.cnpj, obj.codigoRubrica)
        .then((response) => {
          if (response.status == 200) {
            this.montaListaPeriod(response.data.dados);
          }
          this.hasFilesSended = this.filesSuccessAdded.length > 0;
        })
        .catch((error) => {
          console.log("error:", error);
          return helperService.callMakeToast(error, "error");
        });
      this.loadingSpinner = false;
    },

    async ExcluirOE() {
      console.log("this.nCodOp", this.nCodOp);
      console.log("this.cnpj", this.cnpj);
      console.log("this.anoCorte", this.anoCorte);
      await inssService
        .deleteOEbyData(this.nCodOp, this.cnpj, this.anoCorte)
        .then((response) => {
          if (response.data) {
            this.getCalculoProcessado(this.nCodOp, this.cnpj);
          }
        });
    },

    async DeletefromNivel(obj) {
      event.stopPropagation();
      this.loadingSpinner = true;
      console.log("DeletefromNivel");
      switch (obj.nivel) {
        case 1:
          await UploadInssService.processarDeleteInss(
            this.nCodOp,
            obj.codigoRubrica,
            this.cnpj
          ).then((response) => {
            if (response.data) {
              this.getCalculoProcessado(this.nCodOp, this.cnpj);
              // this.showMsgBoxDeleteRubrica();
            }
          });
          break;
        case 2:
          await UploadInssService.processarDeleteInss(
            this.nCodOp,
            obj.codigoRubrica,
            this.cnpj
          ).then((response) => {
            if (response.data) {
              this.getCalculoProcessado(this.nCodOp, this.cnpj);
              // this.showMsgBoxDeleteRubrica();
            }
          });
          break;
        case 3:
          await UploadInssService.processarDeleteInss(
            this.nCodOp,
            obj.codigoRubrica,
            this.cnpj
          ).then((response) => {
            if (response.data) {
              this.getCalculoProcessado(this.nCodOp, this.cnpj);
              // this.showMsgBoxDeleteRubrica();
            }
          });
          break;
        case 4:
          await UploadInssService.processarDeleteOutrasEntidades(
            this.nCodOp,
            obj.nivelId,
            this.cnpj
          ).then((response) => {
            if (response.data) {
              this.getCalculoProcessado(this.nCodOp, this.cnpj);
              // this.showMsgBoxDeleteRubrica();
            }
          });
          break;
      }
      this.loadingSpinner = false;
    },

    async DeletePeriod(obj) {
      this.loadingSpinner = true;
      await inssService
        .DeletePeriodoRubrica(this.nCodOp, this.cnpj, obj.cod_rubr, obj.dt_comp)
        .then((response) => {
          if (response.status == 200) {
            this.montaListaPeriod(response.data.dados);
          }
        })
        .catch((error) => {
          console.log("error:", error);
          return helperService.callMakeToast(error, "error");
        });
      this.loadingSpinner = false;
    },

    async RecoverPeriod(obj) {
      this.loadingSpinner = true;
      await inssService
        .RecoverPeriod(this.nCodOp, this.cnpj, obj.cod_rubr, obj.dt_comp)
        .then((response) => {
          if (response.status == 200) {
            this.montaListaPeriod(response.data.dados);
          }
        })
        .catch((error) => {
          console.log("error:", error);
          return helperService.callMakeToast(error, "error");
        });
      this.loadingSpinner = false;
    },

    async DeleteAllPeriodo(obj) {
      this.loadingSpinner = true;
      console.log("DeleteAllPeriodo");
      await inssService
        .DeleteAllPeriodo(obj.nCodOp, obj.cnpjCei, obj.dt_comp)
        .then((response) => {
          if (response.status == 200) {
            const index = this.listaPeriodos.findIndex(
              (item) => item.dt_comp === obj.dt_comp
            );
            if (index !== -1) {
              const removedItem = this.listaPeriodos.splice(index, 1)[0];
              this.listaPeriodosRecuperar.push(removedItem);
              this.listaPeriodosRecuperar.sort(
                (a, b) => new Date(a.dt_convertida) - new Date(b.dt_convertida)
              );
              // this.listaPeriodos.splice(index, 1);
            }
            this.getCalculoProcessado(obj.nCodOp, obj.cnpjCei);
          }
        })
        .catch((error) => {
          console.log("error:", error);
          this.loadingSpinner = false;
          return helperService.callMakeToast(error, "error");
        });
      this.loadingSpinner = false;
    },

    async RecoverAllPeriodo(obj) {
      this.loadingSpinner = true;
      console.log("RecoverAllPeriodo");
      await inssService
        .RecoverAllPeriodo(obj.nCodOp, obj.cnpjCei, obj.dt_comp)
        .then((response) => {
          if (response.status == 200) {
            const index = this.listaPeriodosRecuperar.findIndex(
              (item) => item.dt_comp === obj.dt_comp
            );
            if (index !== -1) {
              const removedItem = this.listaPeriodosRecuperar.splice(
                index,
                1
              )[0];
              this.listaPeriodos.push(removedItem);
              this.listaPeriodos.sort(
                (a, b) => new Date(a.dt_convertida) - new Date(b.dt_convertida)
              );
              // this.listaPeriodosRecuperar.splice(index, 1);
            }
            this.getCalculoProcessado(obj.nCodOp, obj.cnpjCei);
          }
        })
        .catch((error) => {
          console.log("error:", error);
          this.loadingSpinner = false;
          return helperService.callMakeToast(error, "error");
        });
      this.loadingSpinner = false;
    },

    // saveChanges() {
    //   this.$bvModal
    //     .msgBoxConfirm("Salvar alterações?", {
    //       okVariant: "outline-success",
    //       okTitle: "Salvar",
    //       cancelTitle: "Cancelar",
    //       cancelVariant: "outline-danger",
    //     })
    //     .then((value) => {
    //       if (value === true) {
    //         alert("ok");
    //       }
    //     });
    // },

    showMsgBoxTwo() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxOk("Processo realizado com sucesso!", {
          title: "Confirmação",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          (this.boxTwo = value), console.log("chamou 2");
          this.getCalculoProcessado(this.nCodOp, this.cnpj);
        })
        .catch((err) => {
          // An error occurred
        });
    },

    showMsgBoxDeleteRubrica() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxOk("Rubrica excluida!", {
          title: "Confirmação",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          // An error occurred
        });
    },

    montaListaPeriod(obj) {
      this.listPeriod = [];
      this.listPeriodexcluded = [];
      this.filesSuccessAdded = obj;
      this.filesSuccessAdded.forEach((element) => {
        if (element.periodo_Excluido == 0) {
          this.listPeriod.push({
            dt_comp: element.dt_comp,
            cod_rubr: element.cod_rubr,
            desc_rubrica: element.desc_rubrica,
            vlr_rubr: element.vlr_rubr.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
          });
        } else if (element.periodo_Excluido == 1) {
          this.listPeriodexcluded.push({
            dt_comp: element.dt_comp,
            cod_rubr: element.cod_rubr,
            desc_rubrica: element.desc_rubrica,
            vlr_rubr: element.vlr_rubr.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
          });
        }
      });
      console.log("chamou 3");
      this.getCalculoProcessado(this.nCodOp, this.cnpj);
    },

    montaNiveis() {
      this.relatorioInicial.forEach((element) => {
        switch (element.nivel) {
          case 1:
            this.niveis.nivel1.push({
              nivelId: element.id_Manad_Relatorio_Analise_Inicial,
              codigoRubrica: element.codigo_Rubrica,
              descricao: element.descricao,
              valorInss: element.valor_INSS.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              valorRat: element.valor_RAT.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              valorS5: element.valor_S5.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              valorTotal: element.valor_Total.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              nivel: element.nivel,
            });
            break;
          case 2:
            this.niveis.nivel2.push({
              nivelId: element.id_Manad_Relatorio_Analise_Inicial,
              codigoRubrica: element.codigo_Rubrica,
              descricao: element.descricao,
              valorInss: element.valor_INSS.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              valorRat: element.valor_RAT.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              valorS5: element.valor_S5.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              valorTotal: element.valor_Total.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              nivel: element.nivel,
            });
            break;
          case 3:
            this.niveis.nivel3.push({
              nivelId: element.id_Manad_Relatorio_Analise_Inicial,
              codigoRubrica: element.codigo_Rubrica,
              descricao: element.descricao,
              valorInss: element.valor_INSS.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              valorRat: element.valor_RAT.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              valorS5: element.valor_S5.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              valorTotal: element.valor_Total.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              nivel: element.nivel,
            });
            break;
          case 4:
            this.niveis.outrasEntidades.push({
              nivelId: element.id_Manad_Relatorio_Analise_Inicial,
              descricao: element.descricao,
              valorInss: element.valor_INSS.toLocaleString("pt-br", {
                // aqui é valor pago
                style: "currency",
                currency: "BRL",
              }),
              valorS5: element.valor_S5.toLocaleString("pt-br", {
                // aqui é valor devido
                style: "currency",
                currency: "BRL",
              }),
              valorRat: element.valor_RAT.toLocaleString("pt-br", {
                // aqui é valor credito
                style: "currency",
                currency: "BRL",
              }),
              nivel: element.nivel,
            });
            break;
          case 5:
            // console.log("element--> ",element)
            this.niveis.rubricasExcluidas.push({
              nivelId: element.id_Manad_Relatorio_Analise_Inicial,
              codigoRubrica: element.codigo_Rubrica,
              descricao: element.descricao,
              valorInss: element.valor_INSS.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              valorTotal: element.valor_Total.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              nivel: element.nivel,
            });
            break;
        }
      });
    },

    formatMoney(number) {
      return helperService.formatMoneyPtBR(number);
    },

    updateOrReset() {
      this.$swal({
        icon: "warning",
        title: "Aviso",
        html: `SIM - atualiza o calculo e mantém as alterações, <br> NÃO - refazer o calculo perder alterações`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.value) {
          this.anoCorte = "att";
          this.submit();
          this.anoCorte = "";
        } else {
          this.submit();
          this.anoCorte = "";
        }
      });
    },
  },

  // Exportar() {
  //   Relatorios.Exportar(this.nCodOp).then((response) => {
  //     const url = URL.createObjectURL(
  //       new Blob([response.data], {
  //         type:
  //           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       })
  //     );
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "NovoRelatorio.xlsx");
  //     document.body.appendChild(link);
  //     link.click();
  //   });
  // },
};
</script>
<style scoped>
/* ids */
#aliquotas {
  border: 0.2em solid rgba(61, 169, 56, 0.8);
}

/* classes */
/* .aliquotas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 10%;
} */

.values {
  border: none !important;
}

.ordem-reversa {
  display: flex;
  flex-direction: column-reverse;
}

.ocultarId {
  display: none;
}

.nivel {
  justify-content: center;
  margin-left: 10px;
}

.labelNivel {
  font-size: 24px;
  margin-left: 5px;
  size: "md";
}

.cardAliquotas {
  display: flex;
}

.divNiveis {
  display: flex;
}

.divPeriod {
  margin-top: 0.5rem;
}

.aliquotas {
  margin: 5px;
  align-items: center;
  margin-left: 10px;
}

.lbAliquota {
  margin-right: 10px;
}

.btnOE {
  margin: 0 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.inputSelect {
  width: 100px;
}

.btnSelect,
.inputSelect,
.labelSelect {
  margin: 0 10px;
}

.labelSelect {
  align-self: center;
}
</style>
